<div class="sidebar" [class.compact]="compact">
	<div class="sidebar__logo">
		<img src="assets/img/logo.png" alt="friday logo" />
	</div>

	<div class="sidebar__menu column">
		<a
			*ngFor="let route of routes"
			class="sidebar__menu__item"
			[class.active]="isActiveRoute(route.url)"
			[routerLink]="[route.url]"
		>
			<i class="material-icons">{{ route.icon }}</i>
			<span>{{ "nav." + route.name | translate }}</span>
		</a>
	</div>

	<div class="sidebar__collapse">
		<button class="icon-button" (click)="toggleSidebar()">
			<i class="material-icons" *ngIf="!compact">arrow_back_ios</i>
			<i class="material-icons" *ngIf="compact">arrow_forward_ios</i>
		</button>
	</div>

	<div class="sidebar__user">
		<a class="sidebar__user__info" [routerLink]="['/setting/account']">
			<div class="user-icon gradient-theme" *ngIf="!userInfo.photoURL">
				{{ userInfo.displayName ? userInfo.displayName[0] : userInfo.email[0] }}
			</div>
			<div
				class="user-icon"
				*ngIf="userInfo.photoURL"
				[style.background-image]="userPhoto()"
			></div>

			<div class="user-name column">
				<span class="name" *ngIf="userInfo.displayName">{{
					userInfo.displayName
				}}</span>
				<!-- <span class="email">{{user.email}}</span> -->
			</div>
		</a>

		<button class="icon-button" (click)="signOut()" id="btn-sign-out">
			<i class="material-icons">exit_to_app</i>
		</button>

		<button
			class="icon-button"
			mat-button
			[matMenuTriggerFor]="languageMenu"
			id="btn-language"
		>
			<i class="material-icons">language</i>
		</button>
	</div>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="above">
	<button
		class="center language"
		*ngFor="let language of languages"
		mat-menu-item
		(click)="setLanguage(language)"
	>
		{{ "general." + language | translate }}
	</button>
</mat-menu>
