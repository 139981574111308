export * from "./request";
export * from "./response";

export enum HTTP_METHOD {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
}

export const HTTP_METHODS = [
  HTTP_METHOD.POST,
  HTTP_METHOD.GET,
  HTTP_METHOD.DELETE,
  HTTP_METHOD.PUT,
];

export enum HTTP_CONTENT_TYPE {
  JSON = "json",
  FORM = "form-data",
  URLENCODED = "x-www-form-urlencoded",
}

export const HTTP_HEADERS = [
  "a-im",
  "accept",
  "accept-charset",
  "accept-encoding",
  "accept-language",
  "accept-datetime",
  "access-control-request-method",
  "access-control-request-headers",
  "authorization",
  "cache-control",
  "connection",
  "content-length",
  "content-type",
  "cookie",
  "date",
  "expect",
  "forwarded",
  "from",
  "host",
  "if-match",
  "if-modified-since",
  "if-none-match",
  "if-range",
  "if-unmodified-since",
  "max-forwards",
  "origin",
  "pragma",
  "proxy-authorization",
  "range",
  "referer",
  "te",
  "user-agent",
  "upgrade",
  "via",
  "warning",
  "dnt",
  "x-requested-with",
  "x-csrf-token",
];
