export class Logger {
  task: string;
  constructor(task: string) {
    this.task = task;
  }

  write(message, icon: string, returnValue?: any, depth: number = 3) {
    if (typeof message === "object") {
      console.log(`${icon} [${this.task}]:`);
      console.dir(message, { depth: depth });
    } else {
      console.log(`${icon} [${this.task}]: ` + message);
    }
    if (returnValue !== undefined) return returnValue;
    return this;
  }

  info(message, returnValue?: any, depth: number = 3) {
    return this.write(message, "ℹ️", returnValue, depth);
  }

  success(message, returnValue?: any, depth: number = 3) {
    return this.write(message, "✅", returnValue, depth);
  }

  warn(message, returnValue?: any, depth: number = 3) {
    return this.write(message, "⚠️", returnValue, depth);
  }

  error(message, returnValue?: any, depth: number = 3) {
    return this.write(message, "❌", returnValue, depth);
  }
}
