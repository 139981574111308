import { REGEX_EMOJI } from "../misc";

export enum FORMAT_STYLE {
  bold = "bold",
  italic = "italic",
}

const formatMessage = (style: FORMAT_STYLE, text: string) => {
  const emoji = text.match(REGEX_EMOJI);
  if (emoji) return text;
  let c = "";
  switch (style) {
    case FORMAT_STYLE.bold:
      c = "*";
      break;
    case FORMAT_STYLE.italic:
      c = "_";
      break;
  }
  return `${c}${text}${c}`;
};

export const italic = (text: string) => {
  return formatMessage(FORMAT_STYLE.italic, text);
};

export const bold = (text: string) => {
  return formatMessage(FORMAT_STYLE.bold, text);
};
