import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Language, SupportedLanguages } from "@models/misc";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { User, UserInfo } from "@models/user";
import { SettingService } from "../../services/setting.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input() routes: any[];
	@Input() authService: any;
	userInfo: UserInfo;
	languages: Language[];
	language: Language;
	compact: boolean;
	clientSub: Subscription;

	constructor(
		private router: Router,
		private sanitization: DomSanitizer,
		private settingService: SettingService
	) {}

	ngOnInit() {
		this.clientSub = this.authService.clientChanges.subscribe(client => {
			this.userInfo = this.authService.currentUserInfo();
		});
		this.language = this.settingService.settings.language;
		this.languages = [
			...[this.language],
			...SupportedLanguages.filter(l => l !== this.language),
		];
		this.compact = this.settingService.settings.compact;
	}

	ngOnDestroy() {
		if (this.clientSub) this.clientSub.unsubscribe();
	}

	setLanguage(language: Language) {
		this.language = this.settingService.setLanguage(language);
		this.languages = [
			...[this.language],
			...SupportedLanguages.filter(l => l !== this.language),
		];
	}

	toggleSidebar() {
		this.compact = this.settingService.toggleSidebar();
	}

	isActiveRoute(url: string) {
		return this.router.url.indexOf(url) !== -1;
	}

	userPhoto() {
		return this.sanitization.bypassSecurityTrustStyle(
			`url(${this.userInfo.photoURL})`
		);
	}

	signOut() {
		return this.authService.signOut();
	}
}
