import { environment as defaultEnvironment } from "./environment.default";
export const environment = {
  ...defaultEnvironment,
  ENV: "DEV",
  apiEndpoint: "https://api-dev.getfriday.ai",
  firebase: {
    apiKey: "AIzaSyBfGaxbFr1mgd45gBQaYWlDBEUNwqoe7Jo",
    authDomain: "dev.getfriday.ai",
    databaseURL: "https://magicbot-238102.firebaseio.com",
    projectId: "magicbot-238102",
    storageBucket: "magicbot-238102.appspot.com",
    messagingSenderId: "59964886572",
    appId: "1:59964886572:web:0cfe4dfe4b8ece12",
    measurementId: "G-P1DR4JZTBC",
  },
};
