<div class="chart">
  <span class="chart__header">
    <label class="title">
      <i class="material-icons">{{icon}}</i> <span>{{title}}</span>
    </label>
    <div class="description">
      <span>{{description}}</span>
    </div>
  </span>

  <div class="chart__content" [ngSwitch]="type">
    <ngx-charts-area-chart *ngSwitchCase="ChartType.area" [results]="data" [xAxis]="xAxis" [yAxis]="yAxis"
      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [autoScale]="autoScale"
      [showGridLines]="showGridLines" [scheme]="colorScheme" [gradient]="gradient" [curve]="curve"
      [xAxisTickFormatting]="xFormat" [yAxisTickFormatting]="yFormat" [rotateXAxisTicks]="rotateXAxisTicks"
      [yScaleMin]="yScaleMin">
    </ngx-charts-area-chart>

    <ngx-charts-line-chart *ngSwitchCase="ChartType.line" [results]="data" [xAxis]="xAxis" [yAxis]="xAxis"
      [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [autoScale]="autoScale"
      [showGridLines]="showGridLines" [scheme]="colorScheme" [gradient]="gradient" [curve]="curve"
      [xAxisTickFormatting]="xFormat" [rotateXAxisTicks]="rotateXAxisTicks" [yScaleMin]="yScaleMin">
    </ngx-charts-line-chart>

    <ngx-charts-pie-chart *ngSwitchCase="ChartType.pie" [results]="data" [scheme]="colorScheme" [legend]="true"
      [legendTitle]="''" [legendPosition]="'below'" [labels]="false">
    </ngx-charts-pie-chart>
  </div>

  <div class="chart__footer"></div>

</div>