export const ErrorCode = {
  friday: {
    user_not_found: "friday/user-not-found",
    unsupported_language: "friday/unsupported-language",
    facebook_not_connected: "friday/facebook-not-connected",
    twilio_not_connected: "friday/twilio-not-connected",
  },
  wit: {
    missing_credential: "wit/no-credentials",
    app_not_found: "wit/app-not-found",
    no_auth: "wit/no-auth",
    rate_limit: "wit/rate-limit",
    existing_app: "wit/existing-app",
  },
  firebase: {
    user_not_found: "auth/user-not-found",
  },
  unknown: "unknown",
};
