export interface WhatsappMessageMedia {
  mimetype: string;
  data: string;
  filename?: string;
}

export interface WhatsappLocation {
  latitude: number;
  longitude: number;
  description?: string;
}

export enum WhatsappInstanceState {
  PENDING = "PENDING", // Waiting for scanning QR code
  CONNECTED = "CONNECTED",
  CLOSED = "CLOSED", // Instance Deleted
  ERROR = "ERROR",
  QUEUE = "QUEUE",
}

export enum WhatsappMessageType {
  TEXT = "chat",
  AUDIO = "audio",
  VOICE = "ptt",
  IMAGE = "image",
  VIDEO = "video",
  DOCUMENT = "document",
  STICKER = "sticker",
  LOCATION = "location",
  CONTACT_CARD = "vcard",
  CONTACT_CARD_MULTI = "multi_vcard",
  REVOKED = "revoked",
  UNKNOWN = "unknown",
}

export interface WhatsappMessageMedia {
  data: string;
  filename?: string;
  mimetype: string;
}

export interface WhatsappMessage {
  author: string;
  body: string;
  broadcast: boolean;
  from: string;
  fromMe: boolean;
  hasMedia: boolean;
  hasQuotedMsg: boolean;
  id: any;
  isForwarded: boolean;
  location: WhatsappLocation;
  mentionedIds: string[];
  timestamp: number;
  to: string;
  type: WhatsappMessageType;
  media?: WhatsappMessageMedia;
  mentions?: WhatsappContact[];
  quotedMsg?: WhatsappMessage;
  mimetype?: string;
}

export interface WhatsappChat {
  archived: boolean;
  id: any;
  isGroup: boolean;
  isReadOnly: boolean;
  name: string;
  timestamp: number;
  unreadCount: number;
  messages?: WhatsappMessage[];
}

export interface WhatsappContact {
  id: any;
  isBusiness: boolean;
  isEnterprise: boolean;
  isGroup: boolean;
  isMe: boolean;
  isMyContact: boolean;
  isUser: boolean;
  isWAContact: boolean;
  name?: string;
  number: string;
  pushname: string;
  shortName?: string;
}
