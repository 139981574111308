import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Language } from "@models/misc";
import { ChatformQuestion, ChatformQuestionType, FormStyle } from '@models/messenger';

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input() formStyle: FormStyle = FormStyle.Form;
  @Input() questions: ChatformQuestion[] = [];
  @Input() language: Language;
  @Output() submitResult = new EventEmitter();

  FormStyle: typeof FormStyle = FormStyle;
  ChatformQuestionType: typeof ChatformQuestionType = ChatformQuestionType;
  currentIndex = 0;

  translation = {
    zh: {
      continue: "繼續",
      optional: "(選填)",
    },
    en: {
      continue: "continue",
      optional: "(optional)",
    },
  };

  constructor() { }

  ngOnInit() {
    console.log(this.questions);
  }

  valueChange() {
    console.log(this.questions);
  }

  valid(question: ChatformQuestion) {
    if (question.required && (!question.value || question.value === ""))
      return false;
    return true;
  }

  next(question: ChatformQuestion) {
    if (!question) return;
    if (question.next) {
      const i = this.questions.findIndex((q) => q._id === question.next);
      if (i > 0) this.currentIndex = i;
    } else {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.questions.length) return this.submit();
  }

  submit() {
    let result = {};
    this.questions.forEach((q) => {
      if (
        q.questionType !== ChatformQuestionType.Page &&
        q.questionType !== ChatformQuestionType.Section
      ) {
        result[q.key] = q.value;
      }
    });
    console.log(result);
    this.submitResult.emit(result);
  }
}
