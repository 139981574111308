<ng-container [ngSwitch]="formStyle" *ngIf="language">
  <div class="poll" *ngSwitchCase="FormStyle.Poll">
    <ng-container *ngFor="let question of questions; let i = index">
      <div class="poll__question" *ngIf="currentIndex == i">
        <div class="poll__question__label">{{ question.text[language] }}</div>

        <ng-container [ngSwitch]="question.questionType">
          <input
            *ngSwitchCase="ChatformQuestionType.Input"
            [type]="question.type"
            [required]="question.required"
            [(ngModel)]="question.value"
            [name]="question.key"
            (change)="valueChange()"
            [placeholder]="
              question.required
                ? question.placeholder[language] || ''
                : translation[language]['optional']
            "
            (keydown.enter)="valid(question) ? next(question) : next(null)"
          />

          <div
            *ngSwitchCase="ChatformQuestionType.Radio"
            class="radio-button-group"
          >
            <div class="radio-button" *ngFor="let option of question.options">
              <input
                type="radio"
                [(ngModel)]="question.value"
                [value]="option.value"
                [name]="option.text[language]"
                [id]="option.value"
                (change)="valueChange()"
              />
              <label
                [for]="option.value"
                [class.checked]="option.value == question.value"
                >{{ option.text[language] }}</label
              >
            </div>
          </div>
        </ng-container>

        <button
          class="text-button gradient-dark bg-black"
          (click)="next(question)"
          [disabled]="!valid(question)"
        >
          {{ translation[language]["continue"] }}
        </button>
      </div>
    </ng-container>
  </div>

  <div class="form" *ngSwitchCase="FormStyle.Form">
    <div
      class="form__question"
      *ngFor="let question of questions; let i = index"
    >
      <div class="form__question__label">{{ question.text[language] }}</div>

      <ng-container [ngSwitch]="question.questionType">
        <input
          *ngSwitchCase="ChatformQuestionType.Input"
          [type]="question.type"
          [required]="question.required"
          [(ngModel)]="question.value"
          [name]="question.key"
          (change)="valueChange()"
          [placeholder]="
            question.required
              ? question.placeholder[language] || ''
              : translation[language]['optional']
          "
        />

        <div
          *ngSwitchCase="ChatformQuestionType.Radio"
          class="radio-button-group"
        >
          <div class="radio-button" *ngFor="let option of question.options">
            <input
              type="radio"
              [(ngModel)]="question.value"
              [value]="option.value"
              [name]="option.text[language]"
              [id]="option.value"
              (change)="valueChange()"
            />
            <label
              [for]="option.value"
              [class.checked]="option.value == question.value"
              >{{ option.text[language] }}</label
            >
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
