import { PubsubEvent } from "./pubsub";

export enum WebhookEventName {
  FB_MESSAGES = "fb/messages",
  FB_MESSAGE_ECHOES = "fb/message_echoes",
  FB_MESSAGE_POSTBACKS = "fb/message_postbacks",
  FB_POST_COMMENTS = "fb/post_comments",

  TWILIO_MESSAGES = "twilio/messages",
  TWILIO_SENT = "twilio/sent",

  WHATSAPP_MESSAGES = "whatsapp/messages",
  WHATSAPP_SENT = "whatsapp/sent",
  WHATSAPP_STATE_CHANGED = "whatsapp/state_changed",
  WHATSAPP_PUSH_UNREAD = "whatsapp/push_unread",
  WHATSAPP_HEALTHCHECK = "whatsapp/health_check",
}

export interface WebhookEvent extends PubsubEvent {
  name: WebhookEventName;
}

export const PubsubKeepAlive = "KEEPALIVE";
