import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { curveMonotoneX } from 'd3-shape';

export enum ChartType {
  line = 'line',
  area = 'area',
  pie = 'pie',
  bar = 'bar'
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;

  @Input() footer: string;

  @Input() data: any[];
  @Input() type: ChartType;
  @Input() xLabel: string;
  @Input() yLabel: string;
  @Input() xFormat: any;
  @Input() yFormat: any;
  @Input() xAxis: boolean;
  @Input() yAxis: boolean;
  @Input() colors: string[];

  ChartType: typeof ChartType = ChartType;
  autoScale = false;
  showGridLines = false;
  gradient = true;
  rotateXAxisTicks = false;
  showXAxisLabel = false;
  showYAxisLabel = false;
  colorScheme: any;
  curve = curveMonotoneX;
  yScaleMin = 0;

  constructor() {
  }

  ngOnInit() {
    this.xAxis = this.xAxis === false ? false : true;
    this.yAxis = this.yAxis === false ? false : true;
    this.colorScheme = {
      domain: this.colors || ['#6b48af', '#2a7dd2', '#0cc5b7']
    }
  }

  ngAfterViewInit() {
    this.data = [...this.data];
    this.updateGradient();
    window.onresize = () => this.updateGradient();
  }

  updateGradient() {
    if (!this.gradient) return;
    setTimeout(_ => {
      const areas = document.querySelectorAll('path.area');
      areas.forEach(area => {
        const fill = area.getAttribute('fill');
        if (fill) {
          const id = fill.match(/#.+/)[0];
          area.setAttribute('fill', `url(${window.location.href + id}`);
        }
      });
    }, 200);
  }

}
